import { ImageService } from '@ess-front/angular-components';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { animate, style, transition, trigger } from '@angular/animations';
import { DateTimeFormatService } from '../date-time/date-time-format.service';
import { environment } from '@environment';
import { UtilsService } from '@ess-front/shared';
import { Observable } from 'rxjs';
import { Review } from '../reviewing/models/review.model';
import { ReviewService } from '../reviewing/services/review.service';

@Component({
  selector: 'ess-details-dialog',
  templateUrl: './details-dialog.component.html',
  styleUrls: ['./details-dialog.component.scss'],
  animations: [
    trigger('fadeInAnimation', [
      transition('* => *', [
        style({
          opacity: 0,
        }),
        animate(
          '400ms 150ms ease-in-out',
          style({
            opacity: 1,
          }),
        ),
      ]),
    ]),
  ],
  standalone: false,
})
export class DetailsDialogComponent implements OnInit {
  isPlace: boolean;
  backgroundImage: string;
  detailsURL: string;
  reviews$: Observable<Review[]>;
  averageFeedbackScore: number | null;

  // eslint-disable-next-line
  constructor(
    private readonly reviewService: ReviewService,
    private readonly dateTimeFormatService: DateTimeFormatService,
    private readonly imageService: ImageService,
    private readonly utilsService: UtilsService,
    private matDialogRef: MatDialogRef<DetailsDialogComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: {
      autofocus: boolean;
      date: string;
      details: any;
    },
  ) {
    this.matDialogRef.addPanelClass('experience-details-dialog');
  }

  ngOnInit(): void {
    if (this.data && this.data.details) {
      this.isPlace = !this.data.date;
      const image = this.isPlace
        ? this.data.details && this.data.details.image
        : this.data.details && this.data.details.gallery && this.data.details.gallery[0];
      this.backgroundImage = image ? `url(${this.imageService.getImageUrl(image, 700, 400)})` : null;
      if (this.data.details.type_slug && this.data.details.slug) {
        this.detailsURL = this.utilsService.buildPublicLink(
          this.data.details.slug,
          this.data.details.type_slug,
          environment.travelDomain,
        );
      }
      this.averageFeedbackScore = this.data.details.feedback_average_score;
      this.reviews$ = this.reviewService.getFeedback$(this.data.details.id, this.data.details.object_type);
    }
  }

  getFormattedTime(time: string, durationFormat?: boolean): string {
    return this.dateTimeFormatService.getFormattedTime(time, durationFormat) as string;
  }
}
