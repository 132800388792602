import { Component, Input, Output, EventEmitter } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { ImageService, GalleryFilter } from '@ess-front/angular-components';

@Component({
  selector: 'ess-scroll-gallery',
  templateUrl: './scroll-gallery.component.html',
  styleUrls: ['./scroll-gallery.component.scss'],
  animations: [
    trigger('fadeInAnimation', [
      transition('void => *', [
        style({
          opacity: 0,
        }),
        animate(
          '400ms 150ms ease-in-out',
          style({
            opacity: 1,
          }),
        ),
      ]),
    ]),
  ],
  standalone: false,
})
export class ScrollGalleryComponent {
  collection: any[];
  filterKeys: string[];
  filteredCollection: any[];
  filterValues: { [key: string]: any };
  filterOptions: { [key: string]: string[] };
  filterCategories: GalleryFilter[];
  months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  @Input()
  title: string;
  @Input()
  link: string;
  @Input()
  type?: string;

  @Output()
  itemClicked = new EventEmitter();

  @Input()
  set items(items) {
    const collection = items.map(item => {
      if (item.description) {
        const span = document.createElement('span');
        span.innerHTML = item.description;
        item.description = span.textContent || span.innerText || '';
      }
      return item;
    });

    this.collection = [...collection];
    this.filteredCollection = [...collection];
    this.setFilterOptions(this.collection, this.filterKeys);
  }

  @Input()
  set filters(filtersArray: GalleryFilter[]) {
    this.filterCategories = filtersArray;
    this.filterKeys = filtersArray.map(filter => filter.key);
    this.filterValues = {};
    this.filterKeys.forEach(k => (this.filterValues[k] = false));

    if (this.collection) {
      this.filteredCollection = [...this.collection];
      this.setFilterOptions(this.collection, this.filterKeys);
    }
  }

  constructor(private readonly imageService: ImageService) {}

  setFilterOptions(collection, filters): any {
    if (!filters) {
      return;
    }

    this.filterOptions = filters.reduce((filterOptions, filterKey) => {
      if (filterKey === 'start_date' && collection.some(item => item.start_date)) {
        filterOptions[filterKey] = this.months;
      } else {
        filterOptions[filterKey] = collection
          .reduce((options, item) => {
            if (item[filterKey] && Array.isArray(item[filterKey])) {
              item[filterKey].forEach(tag => {
                if (!options.includes(tag)) {
                  options = [...options, tag];
                }
              });
            } else if (item[filterKey] !== null && !options.includes(item[filterKey])) {
              options = [...options, item[filterKey]];
            }

            return options;
          }, [])
          .sort();
      }

      return filterOptions;
    }, {});
  }

  filterCollection(collection, filter, filterValue: string | boolean = false): any {
    this.filterValues[filter] = filterValue;
    if (filterValue) {
      this.filteredCollection = collection.filter(item => {
        return Object.keys(this.filterValues).every(filterKey => {
          if (this.filterValues[filterKey] === false) {
            return true;
          }

          if (filterKey === 'start_date') {
            if (!item.start_date) {
              return false;
            }

            const monthIndex = this.filterValues[filterKey];
            const itemStartMonth = new Date(item.start_date).getMonth();

            if (item.end_date) {
              const itemEndMonth = new Date(item.end_date).getMonth();

              return monthIndex >= itemStartMonth && monthIndex <= itemEndMonth;
            }

            return monthIndex === itemStartMonth;
          }

          if (Array.isArray(item[filterKey])) {
            return item[filterKey].includes(this.filterValues[filterKey]);
          }

          return item[filterKey] === this.filterValues[filterKey];
        });
      });
    } else {
      this.filteredCollection = collection;
    }
  }

  buildLink(link, item): string {
    if (item.hash !== undefined) {
      return link + '/' + item.hash;
    }

    return link + '/' + item.slug;
  }

  emitItemClicked(event, item) {
    if (
      this.type !== 'days' &&
      !event.target.className.includes('wishList_icon') &&
      !event.target.className.includes('add_day')
    ) {
      this.itemClicked.emit(item);
    }
  }

  getImageUrl(imagePath: string, height: number, width: number): string {
    return this.imageService.getImageUrl(imagePath, height, width);
  }
}
