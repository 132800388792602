/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AutocompleteDirectionsHandler, CustomTravelMode } from '../autocomplete-directions-handler.class';
import { FieldType } from '@ngx-formly/core';
import { debounceTime, distinctUntilChanged, filter, take, takeUntil, tap } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import { MapService } from '../../core/services/map.service';
import { ImageService } from '../../core/services/image.service';
import { ExperienceType, IconService, ICustomLocation, UtilsService } from '@ess-front/shared';

/** Styles from https://snazzymaps.com/style/6857/light-grey-and-blue */
const MAP_STYLES = [
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#444444',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        color: '#f2f2f2',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#444444',
        weight: '5',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#444444',
        weight: '5',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        color: '#dde6e8',
      },
      {
        visibility: 'on',
      },
    ],
  },
];

export enum MAP_TYPE {
  BASIC,
  POINT_TO_POINT,
}

export interface IMapPlaceInfo extends google.maps.places.PlaceResult {
  title?: string;
  description?: string;
  address?: string;
  address_object?: any;
  image?: string;
  slug?: string;
  type_slug?: ExperienceType;
  id?: number;
  booking_hash?: string;
}

@Component({
  selector: 'lib-ess-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  standalone: false,
})
export class MapComponent extends FieldType implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('map', { static: false }) gmap: ElementRef;

  /* eslint-disable no-underscore-dangle */
  @Input() entity: any;
  @Input() isLazy = false;

  get locations(): google.maps.LatLngLiteral[] {
    return this._locations;
  }

  @Input() set locations(value: google.maps.LatLngLiteral[]) {
    this._locations = value;
    if (this.isLoaded) {
      this.createRoute();
      this.createMarkers();
    }
  }

  get places(): IMapPlaceInfo[] {
    return this._places;
  }

  /**
   * Places contains the locations info, for window info and for drawing routes between origin - destination.
   * @param value
   */
  @Input() set places(value: IMapPlaceInfo[]) {
    this._places = value;
    if (this.isLoaded) {
      this.createMarkers();
      this.createInfoWin();
    }
  }

  get selectedType(): string {
    return this._selectedType;
  }

  @Input() set selectedType(value: string) {
    this._selectedType = value;

    if (this.isLoaded && value) {
      this.createMarkers();
    }
  }

  @Input() gestureHandling = 'auto';
  @Input() height = '500px';
  @Input() isUsingForm = true;
  @Input() lng: number;
  @Input() mapOptions: google.maps.MapOptions = {};
  @Input() title: string;
  @Input() travelMode: google.maps.TravelMode | CustomTravelMode;
  @Input() type = MAP_TYPE.BASIC;
  @Input() width = '500px';
  @Input() zoom = 12;

  @Output() clickMarker = new EventEmitter<IMapPlaceInfo>();
  @Output() clickReadMore = new EventEmitter<IMapPlaceInfo>();

  isLoaded = false;
  isDisabled = true;
  types = MAP_TYPE;
  map: google.maps.Map;
  mapBounds: google.maps.LatLngBounds;

  private _selectedType: string;
  private _places: IMapPlaceInfo[];
  private _locations: google.maps.LatLngLiteral[];
  private directionsHandler: AutocompleteDirectionsHandler;
  private intersectionSubject$: Subject<boolean> = new Subject<boolean>();
  private observer: IntersectionObserver;
  /* eslint-enable no-underscore-dangle */

  private icons = {
    NONE: {
      small: `${this.environment.cloudinaryURL}v1609233028/icons/mapa_inactive_grey.svg`,
      inactive: `${this.environment.cloudinaryURL}v1609233028/icons/mapa_inactive_grey.svg`,
      active: `${this.environment.cloudinaryURL}v1609233027/icons/mapa_active.svg`,
    },
    [ExperienceType.ACCOMMODATION]: {
      small: `${this.environment.cloudinaryURL}v1611221816/icons/mapa-stay-inactive-small.svg`,
      inactive: `${this.environment.cloudinaryURL}v1611221816/icons/mapa-stay-inactive.svg`,
      active: `${this.environment.cloudinaryURL}v1611221814/icons/mapa-stay-active.svg`,
    },
    [ExperienceType.EAT_DRINK]: {
      small: `${this.environment.cloudinaryURL}v1611221812/icons/mapa-eatdrink-inactive-small.svg`,
      inactive: `${this.environment.cloudinaryURL}v1611221812/icons/mapa-eatdrink-inactive.svg`,
      active: `${this.environment.cloudinaryURL}v1611221812/icons/mapa-eatdrink-active.svg`,
    },
    [ExperienceType.EXPERIENCE]: {
      small: `${this.environment.cloudinaryURL}v1611221812/icons/mapa-do-inactive-small.svg`,
      inactive: `${this.environment.cloudinaryURL}v1611221812/icons/mapa-do-inactive.svg`,
      active: `${this.environment.cloudinaryURL}v1611221812/icons/mapa-do-active.svg`,
    },
    [ExperienceType.PLACES_INTEREST]: {
      small: `${this.environment.cloudinaryURL}v1611221814/icons/mapa-see-inactive-small.svg`,
      inactive: `${this.environment.cloudinaryURL}v1611221814/icons/mapa-see-inactive.svg`,
      active: `${this.environment.cloudinaryURL}v1611221814/icons/mapa-see-active.svg`,
    },
    [ExperienceType.TRANSPORTATION]: {
      small: `${this.environment.cloudinaryURL}v1611221777/icons/vuelo.svg`,
      inactive: `${this.environment.cloudinaryURL}v1611221777/icons/vuelo.svg`,
      active: `${this.environment.cloudinaryURL}v1611221777/icons/vuelo.svg`,
    },
  };

  private info: google.maps.InfoWindow[];
  private markers: google.maps.Marker[];
  private destroy$ = new Subject<void>();

  constructor(
    @Inject('env') private environment: any,
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private mapService: MapService,
    private iconService: IconService,
    private utilsService: UtilsService,
    private readonly imageService: ImageService,
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  ngOnInit(): void {
    const googleLoaderObservable$ = this.mapService.isLoaded$.pipe(
      distinctUntilChanged(),
      filter(value => value),
      takeUntil(this.destroy$),
    );

    if (!this.isLazy) {
      googleLoaderObservable$.subscribe(isLoaded => {
        this.isLoaded = isLoaded;
        this.loadMap();
      });
    }

    if (this.isLazy) {
      const intersectionObservable$ = this.intersectionSubject$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        filter(IsIntersecting => IsIntersecting),
        takeUntil(this.destroy$),
      );

      combineLatest([googleLoaderObservable$, intersectionObservable$])
        .pipe(
          tap(() => {
            this.loadMap();
            this.observer.disconnect();
          }),
          take(1),
        )
        .subscribe();
    }

    if (this.isUsingForm) {
      this.type =
        this.to.subtype && this.to.subtype === MAP_TYPE.POINT_TO_POINT ? MAP_TYPE.POINT_TO_POINT : MAP_TYPE.BASIC;
    }
  }

  ngAfterViewInit() {
    if (this.isLazy) {
      this.createObserver();
    }
  }

  centerTo(index: number = null, coords: google.maps.LatLng = null): void {
    if (this.isLoaded) {
      const marker = this.getMarker(index);
      if (marker) {
        this.focusAndOpen(index, marker, this.setIconByCategory(index).active);
      } else if (coords) {
        this.map.setCenter(coords);
      }
    }
  }

  centerToCoverAllMarkers(): void {
    if (this.mapBounds) {
      this.map.fitBounds(this.mapBounds);
    }
  }

  // Before open the info we close every info wind already opened.
  openInfo(index = 0): void {
    const marker = this.getMarker(index);
    if (this.info && this.info.length > index && this.info[index]) {
      this.info[index].open(this.map, marker);
    }
  }

  private getMarker(index = 0): google.maps.Marker {
    return this.markers && this.markers.length > index ? this.markers[index] : null;
  }

  private getPlace(index = 0): IMapPlaceInfo {
    return this.places && this.places.length > index ? this.places[index] : null;
  }

  private getEntity(): void {
    if (this.entity && Object.prototype.hasOwnProperty.call(this.entity, 'location')) {
      const coords = this.mapService.fromCustomLocation(this.entity.location);
      this.locations = coords ? [coords.toJSON()] : [];
    }
  }

  private handlerLoader(): boolean {
    const mapConfig = {
      ...{
        mapTypeControl: this.type === MAP_TYPE.BASIC,
        gestureHandling: this.gestureHandling,
        styles: MAP_STYLES,
        zoom: this.zoom,
        streetViewControl: false,
      },
      ...this.mapOptions,
    } as google.maps.MapOptions;
    this.map = new google.maps.Map(this.elementRef.nativeElement.querySelector('#map'), mapConfig);

    // Sometimes the map shows a blank map when has not LatLng
    setTimeout(() => {
      if (this.locations && this.locations.length === 1) {
        const center =
          this.locations && this.locations.length && this.locations[0]
            ? this.locations[0]
            : { lat: 41.40338, lng: 2.17403 };
        this.map.setCenter(center);
      }
    });

    // Click on map
    this.map.addListener('click', () => this.resetMarkersAndCloseInfoWin());

    // DirectionsService creation
    this.directionsHandler = new AutocompleteDirectionsHandler(this.map, this.environment);
    this.directionsHandler.travelMode = this.travelMode;

    this.getEntity();
    this.createMarkers();
    this.createRoute();
    this.createInfoWin();

    return true;
  }
  /**
   * When is point to point, the route should be drawn
   * @private
   */
  private createRoute(): void {
    if (this.locations && this.locations.length > 1 && this.type === MAP_TYPE.POINT_TO_POINT) {
      this.directionsHandler.originLocation = this.locations[0];
      this.directionsHandler.destinationLocation = this.locations[1];
      this.directionsHandler.route().then(() => {
        this.enableMapElement();
      });
    }
  }

  /** When the map is not a point to point where the markers are drawn by the route service,
   * the markers are created for showing a basic map */
  private createMarkers(): void {
    this.clearMarkers();
    if (this.locations && this.locations.length && this.type === MAP_TYPE.BASIC) {
      // Center to cover all markers
      this.mapBounds = new google.maps.LatLngBounds();
      this.markers = this.locations.map((loc, i) => {
        this.mapBounds.extend(loc);
        return this.addMarker(loc, i);
      });

      if (this.locations.length > 1) {
        setTimeout(() => this.centerToCoverAllMarkers(), 0);
      } else {
        this.map.setCenter(this.locations[0]);
      }

      this.enableMapElement();
    }
  }

  /**
   * The info window is provided by places
   * @private
   */
  private createInfoWin(): void {
    this.info = [];
    if (this.places) {
      this.places.map(place => this.info.push(this.addInfoWindow(place)));
    }
  }

  /**
   * An info popup with the place's data
   * @param place
   * @private
   */
  private addInfoWindow(place: IMapPlaceInfo): google.maps.InfoWindow {
    let info = null;
    if (
      place &&
      Object.keys(place).length &&
      Object.prototype.hasOwnProperty.call(place, 'title') &&
      Object.prototype.hasOwnProperty.call(place, 'description') &&
      place.title &&
      place.description
    ) {
      const image = place.image ? this.imageService.getImageUrl(place.image, 100, 180) : null;
      const content = `
        <div class="info">
          <div class="link">${this.iconService.getIcon('map_popup_link')}</div>
          ${image ? `<figure class="m-0 full-w"><img src="${image}" width="180"/></figure>` : ''}
          <div class="texts center">
            ${place.title ? '<h4 class="m-0">' + place.title + '</h4>' : ''}
            ${place.address_object ? '<p class="m-0">' + place.address_object.full_address + '</p>' : ''}
          </div>
        </div>
      `;

      info = new google.maps.InfoWindow({
        content,
        maxWidth: 180,
      });

      google.maps.event.addListener(info, 'closeclick', () => this.resetMarkersAndCloseInfoWin());
    }
    return info;
  }

  /**
   * Create and return a marker. Also, create and link the info popup with the marker. When the marker is clicked the popup is shown.
   * @param position
   * @param place
   * @private
   */
  private addMarker(position: google.maps.LatLngLiteral, i: number): google.maps.Marker {
    if (!position || !position.lat || !position.lng) {
      return new google.maps.Marker();
    }

    const icons = this.setIconByCategory(i);
    const isTyped = this.hasCurrentType(i);
    const marker = new google.maps.Marker({
      position,
      title: this.places[i].title ?? 'marker' + i,
      icon: isTyped ? icons.inactive : icons.small,
      map: this.map,
    } as google.maps.MapOptions);

    // Only the icons with the selected type has the click event.
    if (isTyped) {
      google.maps.event.addListener(marker, 'click', () => this.clickMarkerEvent(marker, icons, i));
    }

    return marker;
  }

  /**
   * A helper for finding out if the place has an equal type than the type already selected
   * @param index
   * @private
   */
  private hasCurrentType(index: number): boolean {
    // If the selected type is 'aall' then every item has the click event.
    return (
      !Object.values(ExperienceType).includes(this.selectedType as ExperienceType) ||
      this.selectedType === this.getPlace(index).type_slug
    );
  }

  /**
   * When a marker is clicked, the rest of markers change to inactive icon and the selected gets the active icon.
   * Also, all info windows are closed. Finally, the event 'click' is sent.
   * @param marker
   * @param icons
   * @param i
   * @private
   */
  private clickMarkerEvent(
    marker: google.maps.Marker,
    icons: { active: string; inactive: string; small: string },
    i: number,
  ): void {
    this.focusAndOpen(i, marker, icons.active);

    const place = this.getPlace(i);
    if (place) {
      this.clickMarker.emit(place);
    }
  }

  // 1 All markers icons are removed.
  // 2 Add to the target marker the active icon (black icon).
  // 3 Adding zoom and marker movements (bounce).
  // 4 The info windows dees opened.
  private focusAndOpen(index: number, marker: google.maps.Marker, icon: string): void {
    this.resetMarkersAndCloseInfoWin();
    marker.setIcon(icon);
    this.focusMarker(marker);
    this.openInfo(index);

    setTimeout(() => {
      const $link = this.elementRef.nativeElement.querySelector('.link');
      if ($link) {
        $link.addEventListener('click', () => this.clickReadMore.emit(this.getPlace(index)));
      }
    });
  }

  private resetMarkersAndCloseInfoWin(): void {
    this.markers.forEach((m: google.maps.Marker, index: number) => {
      if (this.hasCurrentType(index)) {
        m.setIcon(this.setIconByCategory(index).inactive);
      }
    });
    if (this.info) {
      this.info.filter(info => info).forEach(win => win.close());
    }
  }

  /**
   * Sometimes the map needs to focus in one point. The marker has a short animation.
   * @param marker
   * @private
   */
  private focusMarker(marker: google.maps.Marker): void {
    if (marker) {
      this.map.panTo(marker.getPosition());
      marker.setAnimation(google.maps.Animation.BOUNCE);

      setTimeout(() => {
        marker.setAnimation(null);
      }, 1200);
    }
  }

  private prepareForm(): void {
    // It's a formly's parent
    if (this.isUsingForm) {
      // Listening value changes
      this.formControl.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((data: any) => {
        if (data) {
          this.locations = Object.values(data)
            .map((location: ICustomLocation) =>
              location ? this.mapService.fromCustomLocation(location).toJSON() : null,
            )
            .filter(location => location);
        }
      });
    }
  }

  /**
   * Clear the map of markers
   * @private
   */
  private clearMarkers(): void {
    if (this.markers) {
      this.markers.forEach((m: google.maps.Marker) => {
        google.maps.event.clearListeners(m, 'click');
        m.setMap(null);
      });
    }
    this.markers = [];
  }

  private setIconByCategory(i: number): { active: string; inactive: string; small: string } {
    const item = this.getPlace(i);
    return item && Object.prototype.hasOwnProperty.call(item, 'type_slug')
      ? this.icons[item.type_slug]
      : this.icons.NONE;
  }

  private createObserver(): void {
    const callback = ([entry]) => {
      this.intersectionSubject$.next(entry.isIntersecting);
    };

    this.observer = this.utilsService.createIntersectionObserver(callback, this.elementRef.nativeElement);
  }

  private loadMap(): void {
    this.travelMode = this.travelMode ? this.travelMode : google.maps.TravelMode.WALKING;
    this.prepareForm();
    this.handlerLoader();
  }

  private enableMapElement(): void {
    if (this.isDisabled) {
      this.isDisabled = false;
      this.changeDetectorRef.detectChanges();
    }
  }
}
